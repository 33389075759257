<template>
  <section class="section">
    <!-- Loading Animation -->
    <div v-if="isLoading" class="loading-container">
      <button class="button is-loading">{{ $t("loading") }}</button>
    </div>

    <div class="container" v-else>
      <div class="banner">
        <img src="@/assets/Banner-3.png" alt="Banner" />
      </div>

      <div class="mt-5">
        <!-- Search Field -->
        <div class="field">
          <input
            class="input is-black is-normal"
            type="text"
            v-model="searchTitle"
            :placeholder="$t('searchPlaceholder')"
          />
        </div>

        <!-- Display Sections When Search Bar is Empty -->
        <div v-if="!searchTitle">
          <!-- Latest Posts Section -->
          <div class="latest-post-section">
            <h1 class="title is-3">{{ $t("latestPostsTitle") }}</h1>
            <div class="card latest-post-card" :class="{ rtl: isRtl }">
              <div class="card-image-half">
                <figure class="image is-4by3">
                  <img :src="latestPost.imageUrl" alt="Latest Post" />
                </figure>
              </div>
              <div class="card-content-half">
                <!-- <h2 class="title is-1" style="color: white">
                  {{ latestPost.title }}
                </h2> -->
                <p class="title is-2 mt-5 mb-5" style="color: white">
                  {{ latestPost.description }}
                </p>
                <router-link
                  :to="{
                    name: 'PostView',
                    params: { slug: latestPost.title, docId: latestPost.id },
                  }"
                  class="button is-dark"
                >
                  {{ $t("readNowBtn") }}
                </router-link>
              </div>
            </div>
          </div>

          <!-- Section Divider -->
          <div class="section-divider-1"></div>

          <!-- Most Engaged Posts Section -->
          <div class="most-engaged-posts-section">
            <h1 class="title is-3">{{ $t("mostEngagedPostsTitle") }}</h1>
            <div class="columns is-multiline">
              <div
                v-for="post in mostEngagedPosts"
                :key="post.id"
                class="column is-one-third"
              >
                <BlogPost :post="post" />
              </div>
            </div>
          </div>

          <!-- Section Divider -->
          <div class="section-divider-2"></div>

          <!-- Older Posts Section -->
          <div class="older-posts-section">
            <h1 class="title is-3">{{ $t("olderPostsTitle") }}</h1>
            <div class="columns is-multiline">
              <div
                v-for="post in olderPosts"
                :key="post.id"
                class="column is-one-third"
              >
                <BlogPost :post="post" />
              </div>
            </div>
          </div>
        </div>

        <!-- Search Results -->
        <div v-else>
          <div class="columns is-multiline">
            <div
              v-for="post in filteredPosts"
              :key="post.id"
              class="column is-one-third"
            >
              <BlogPost :post="post" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import { computed, onMounted, ref, watchEffect, getCurrentInstance } from "vue";
import { db } from "@/firebase/config";
import BlogPost from "@/components/BlogPostView.vue";
import { collection, getDocs } from "firebase/firestore";
import { useStore } from "vuex";

export default {
  components: {
    BlogPost,
  },
  setup() {
    const allPosts = ref([]);
    const latestPost = ref(null);
    const mostEngagedPosts = ref([]);
    const olderPosts = ref([]);
    const searchTitle = ref("");
    const store = useStore();
    const filteredPosts = ref([]);
    const isLoading = ref(true);

    const instance = getCurrentInstance();
    const selectedLanguage = ref(); // default language

    if (instance && instance.proxy.$i18n) {
      selectedLanguage.value = instance.proxy.$i18n.locale;
    }

    const isRtl = computed(() => {
      return selectedLanguage.value === "en-ar";
    });

    onMounted(async () => {
      const querySnapshot = await getDocs(collection(db, "Posts"));
      const fetchedPosts = [];
      querySnapshot.forEach((doc) => {
        fetchedPosts.push({ id: doc.id, ...doc.data() });
      });

      // Sorting posts by creation date (assuming createdAt is a Firestore Timestamp)
      const sortedPosts = fetchedPosts.sort(
        (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()
      );

      allPosts.value = sortedPosts;

      // Set latest post
      latestPost.value = sortedPosts[0];

      // Remove latest post from the list
      const remainingPosts = sortedPosts.slice(1);

      // Randomly select 6 posts for mostEngagedPosts
      mostEngagedPosts.value = getRandomPosts(remainingPosts, 6);

      // Remove selected posts from remainingPosts to avoid duplication
      const engagedPostIds = mostEngagedPosts.value.map((post) => post.id);
      olderPosts.value = remainingPosts.filter(
        (post) => !engagedPostIds.includes(post.id)
      );

      isLoading.value = false;
    });

    // Function to get random posts
    function getRandomPosts(postsArray, count) {
      const shuffled = postsArray.slice().sort(() => 0.5 - Math.random());
      return shuffled.slice(0, count);
    }

    // Update filteredPosts when searchTitle changes
    watchEffect(() => {
      if (searchTitle.value === "") {
        filteredPosts.value = [];
      } else {
        // Search among all posts
        filteredPosts.value = allPosts.value.filter((post) => {
          return post.title
            .toLowerCase()
            .includes(searchTitle.value.toLowerCase());
        });
      }
    });

    return {
      latestPost,
      mostEngagedPosts,
      olderPosts,
      filteredPosts,
      searchTitle,
      userProfile: computed(() => store.state.userProfile),
      isAuthenticated: computed(() => store.getters.isAuthenticated),
      isLoading,
      isRtl,
    };
  },
};
</script>


<style scoped>
.container {
  max-width: 80vw;
  padding: 0 20px;
}

.banner img {
  height: auto;
  width: 100%;
  display: block;
  position: sticky;
}

.latest-post-section,
.most-engaged-posts-section,
.older-posts-section {
  margin: 2rem 0;
}

.latest-post-card {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.862);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.latest-post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.latest-post-card.rtl {
  flex-direction: row-reverse;
}

.card-content-half,
.card-image-half {
  flex: 1;
}

.card-content-half {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-content-half .button {
  margin-top: 1rem;
}

.card-image-half img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.columns.is-multiline {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.column {
  padding: 0.75rem;
  display: flex;
}

.column > * {
  flex-grow: 1;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.section-divider-1 {
  height: 10px; /* Adjust the height as needed */
  background-image: url("~@/assets/LineImage.png");
  background-repeat: no-repeat;
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Center the image */
  position: relative;
  width: 100%;
  margin: 2rem 0; /* Vertical spacing around the divider */
  border: none;
}

.section-divider-2 {
  height: 4px; /* Adjust the height as needed */
  background-image: url("~@/assets/LineImage.png");
  background-repeat: no-repeat;
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Center the image */
  position: relative;
  width: 100%;
  margin: 2rem 0; /* Vertical spacing around the divider */
  border: none;
  /* Optional: Add a border radius if desired */
  /* border-radius: 2px; */
}

@media (max-width: 768px) {
  .latest-post-card {
    flex-direction: column;
  }
  .latest-post-card.rtl {
    flex-direction: column;
  }
  .card-content-half {
    padding: 1rem;
  }
}
</style>
