<template>
  <router-link
    :to="{
      name: 'PostView',
      params: { slug: post.title, docId: post.id },
    }"
    class="mini-post-card"
  >
    <div class="card">
      <div class="card-image">
        <figure class="image is-4by3">
          <img :src="post.imageUrl" alt="Post image" />
        </figure>
      </div>
      <div class="card-content">
        <p class="title is-6">{{ post.title }}</p>
        <p class="subtitle is-7 mt-2">{{ post.author }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.mini-post-card {
  text-decoration: none;
  color: inherit;
}

.mini-post-card .card {
  box-shadow: none;
  padding: 20px;
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.mini-post-card .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.mini-post-card .card-image {
  margin-bottom: 0.5rem;
}

.mini-post-card .card-content {
  padding: 0;
}

.mini-post-card .title.is-6 {
  margin-bottom: 0.25rem;
}

.mini-post-card .subtitle.is-7 {
  margin-bottom: 0;
  color: #7a7a7a;
}
</style>
