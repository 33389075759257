<template>
  <section class="section">
    <div class="container mt-2 mb-2">
      <!-- Profile Section -->
      <div class="columns is-vcentered">
        <div class="column is-one-third">
          <figure class="image is-square">
            <img
              class="is-rounded"
              src="../assets/profilepic.jpg"
              alt="Author"
            />
          </figure>
        </div>
        <div class="column">
          <h1 class="title is-2">{{ $t("authorName") }}</h1>
          <h2 class="subtitle is-4">{{ $t("aboutRole") }}</h2>
          <div class="content">
            <p>{{ $t("aboutText1") }}</p>
            <p>{{ $t("aboutText2") }}</p>
            <p>{{ $t("aboutText3") }}</p>
          </div>
          <!-- Social Media Icons -->
          <div class="social-icons">
            <a href="https://www.instagram.com/vavmae/" target="_blank">
              <span class="icon is-medium">
                <i class="fab fa-instagram fa-2x"></i>
              </span>
            </a>
            <a href="https://x.com/vavmae?mx=2" target="_blank">
              <span class="icon is-medium">
                <i class="fab fa-twitter fa-2x"></i>
              </span>
            </a>
            <a
              href="https://www.linkedin.com/in/mariam-al-ayoubi/"
              target="_blank"
            >
              <span class="icon is-medium">
                <i class="fab fa-linkedin fa-2x"></i>
              </span>
            </a>
            <!-- Add more social icons as needed -->
          </div>
        </div>
      </div>

      <!-- Additional Sections (e.g., Education, Experience) -->
      <!-- You can add more content here to provide a comprehensive view of your background. -->
    </div>
  </section>
</template>

<style scoped>
/* Consistent container styling */
.container {
  max-width: 80vw;
  padding: 0 20px;
}

/* Centering and styling the columns */
.columns.is-vcentered {
  align-items: center;
}

/* Making the image square and responsive */
.image.is-square {
  width: 100%;
  padding-bottom: 100%; /* Maintain square aspect ratio */
  position: relative;
}

.image.is-square img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Styling the social media icons */
.social-icons {
  margin-top: 1rem;
}

.social-icons a {
  margin-right: 1rem;
  color: #4a4a4a;
}

.social-icons a:hover {
  color: #3273dc; /* Bulma's primary color */
}

.social-icons .icon {
  vertical-align: middle;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .columns {
    flex-direction: column;
  }
  .column.is-one-third {
    width: 100%;
  }
  .image.is-square {
    margin-bottom: 1.5rem;
  }
}
</style>
