import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ContactView from '../views/ContactView.vue'
import PostView from '../views/PostView.vue'
import ErrorPage from '../views/ErrorPage.vue'



// Importing the Vuex store
import store from '@/store/index'
import TermsAndConditions from '@/views/TermsAndConditions.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import CopyrightPolicy from '@/views/CopyrightPolicy.vue'

// Navigation guard to prevent authenticated users from accessing unauthenticated routes
const requireNoAuth = (to, from, next) => {
  //console.log('No Auth Required --> ', store.getters.isAuthenticated)
  if (store.getters.isAuthenticated) {
    next('/'); // Redirect to dashboard if already authenticated
  } else {
    next(); // Proceed to the next middleware if not authenticated
  }
}

// Navigation guard to check if the user is authenticated
const requireAuth = (to, from, next) => {
  //console.log('Require Auth -->', store.getters.isAuthenticated)
  if (store.getters.isAuthenticated) {
    next(); // Proceed to the next middleware if authenticated
  } else {
    next('/login'); // Redirect to login if not authenticated
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/post/:slug/:docId',
    name: 'PostView',
    component: PostView,
  },
  {
    path: '/terms-and-conditions',
    name: 'termsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/copyright-policy',
    name: 'copyrightPolicy',
    component: CopyrightPolicy
  },

  // 404 Error
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: ErrorPage,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
})

export default router
