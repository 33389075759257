<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>&copy; {{ $t("footerText") }}</p>
      <div class="footer-links">
        <router-link to="/terms-and-conditions">{{
          $t("termsAndConditions")
        }}</router-link>
        |
        <router-link to="/privacy-policy">{{
          $t("privacyPolicy")
        }}</router-link>
        |
        <router-link to="/copyright-policy">{{
          $t("copyrightPolicy")
        }}</router-link>
      </div>
      <p class="developed-by">
        {{ $t("developedBy") }}
        <a href="https://o3consultancy.ae" target="_blank">O3 Consultancy</a>
      </p>
      <p>{{ versionNumber }}</p>
    </div>
  </footer>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    return { versionNumber: computed(() => store.state.versionNumber) };
  },
};
</script>

<style scoped>
.footer {
  background-color: #f5f5f5;
  padding: 2rem 1.5rem;
}

.footer-links {
  margin-top: 1rem;
}

.footer-links a {
  margin: 0 0.5rem;
  color: #4a4a4a;
}

.footer-links a:hover {
  color: #3273dc; /* Bulma's primary color */
}

.developed-by {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #7a7a7a;
}

.developed-by a {
  color: #7a7a7a;
  text-decoration: underline;
}

.developed-by a:hover {
  color: #3273dc;
}
</style>
