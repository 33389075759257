<template>
  <section class="section">
    <div class="container mt-5 mb-5">
      <!-- Contact Header and Form in Columns -->
      <div class="columns is-vcentered">
        <!-- Left Column: Contact Information -->
        <div class="column is-half">
          <h1 class="title is-2">{{ $t("contactTitle") }}</h1>
          <h2 class="subtitle is-4">{{ $t("contactSubtitle") }}</h2>
          <p>
            <strong>{{ $t("emailLabel") }}:</strong> info@thesyrianblog.com
          </p>
          <p>
            <strong>{{ $t("addressTitle") }}:</strong> {{ $t("addressValue") }}
          </p>
          <!-- Social Media Icons -->
          <div class="social-icons">
            <a href="https://www.instagram.com/vavmae/" target="_blank">
              <span class="icon is-medium">
                <i class="fab fa-instagram fa-2x"></i>
              </span>
            </a>
            <a href="https://x.com/vavmae?mx=2" target="_blank">
              <span class="icon is-medium">
                <i class="fab fa-twitter fa-2x"></i>
              </span>
            </a>
            <a
              href="https://www.linkedin.com/in/mariam-al-ayoubi/"
              target="_blank"
            >
              <span class="icon is-medium">
                <i class="fab fa-linkedin fa-2x"></i>
              </span>
            </a>
            <!-- Add more social icons as needed -->
          </div>
        </div>

        <!-- Right Column: Contact Form -->
        <div class="column is-half">
          <div class="box">
            <h3 class="subtitle has-text-weight-semibold">
              {{ $t("formTitle") }}
            </h3>
            <form @submit.prevent="submitForm">
              <div class="field">
                <label class="label">{{ $t("nameLabel") }}</label>
                <div class="control">
                  <input
                    class="input is-black"
                    type="text"
                    v-model="form.name"
                    :placeholder="$t('namePlaceholder')"
                    required
                  />
                </div>
              </div>
              <div class="field">
                <label class="label">{{ $t("emailLabel") }}</label>
                <div class="control">
                  <input
                    class="input is-black"
                    type="email"
                    v-model="form.email"
                    :placeholder="$t('emailPlaceholder')"
                    required
                  />
                </div>
              </div>
              <div class="field">
                <label class="label">{{ $t("messageLabel") }}</label>
                <div class="control">
                  <textarea
                    class="textarea is-black"
                    v-model="form.message"
                    :placeholder="$t('messagePlaceholder')"
                    required
                  ></textarea>
                </div>
              </div>
              <div class="control">
                <button class="button is-black is-rounded" type="submit">
                  {{ $t("submitButton") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "@/firebase/config";
import { useRouter } from "vue-router";

export default {
  setup() {
    const form = ref({
      name: "",
      email: "",
      message: "",
      createdAt: serverTimestamp(),
    });
    const router = useRouter();

    const submitForm = async () => {
      try {
        // Add the contact info to Firestore
        await addDoc(collection(db, "Contact-Forms"), form.value);

        // Reset form fields
        form.value = {
          name: "",
          email: "",
          message: "",
          createdAt: serverTimestamp(),
        };

        // Redirect or show a success message
        alert("Thank you for contacting us!");
        router.push("/");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };

    return {
      form,
      submitForm,
    };
  },
};
</script>

<style scoped>
/* Consistent container styling */
.container {
  max-height: 100vh;
  max-width: 80vw;
  padding: 0 20px;
}

/* Centering and styling the columns */
.columns.is-vcentered {
  align-items: center;
}

/* Styling the social media icons */
.social-icons {
  margin-top: 1rem;
}

.social-icons a {
  margin-right: 1rem;
  color: #4a4a4a;
}

.social-icons a:hover {
  color: #3273dc; /* Bulma's primary color */
}

.social-icons .icon {
  vertical-align: middle;
}

/* Form box styling */
.box {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow effect */
  border-radius: 5px; /* Rounded corners */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .columns {
    flex-direction: column;
  }
  .column.is-half {
    width: 100%;
  }
}
</style>
