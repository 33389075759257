<template>
  <section class="section">
    <div class="container mt-5">
      <h1 class="title">{{ $t("copyrightPolicyTitle") }}</h1>
      <div class="content" v-html="$t('copyrightPolicyContent')"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.container {
  max-width: 80vw;
  padding: 0 20px;
}

/* Adjust heading styles if needed */
.content h3 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  color: #333;
}

/* Adjust paragraph styles if needed */
.content p {
  margin-bottom: 1em;
  line-height: 1.6;
}
</style>
