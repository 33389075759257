<template>
  <div class="section">
    <!-- Loading Animation -->
    <div v-if="isLoading" class="loading-container">
      <button class="button is-loading">{{ $t("loading") }}</button>
    </div>
    <div class="container mt-5" v-else>
      <!-- Back Button -->
      <router-link to="/" class="button is-black mb-5 is-rounded">
        <span class="icon" v-if="!isRtl">
          <i class="fas fa-arrow-left"></i>
        </span>
        <span>{{ $t("back2HomeBtn") }}</span>
      </router-link>
      <!-- Main Content -->
      <div class="columns">
        <!-- Post Content -->
        <div class="column is-two-thirds">
          <div :class="{ 'is-rtl': isRtl }">
            <!-- Hero Section -->
            <h1 class="title">{{ post.title }}</h1>

            <!-- Body -->
            <div class="content" v-html="post.body"></div>

            <!-- Author and CreatedAt -->
            <div class="columns">
              <div class="column">
                <p>
                  <strong>{{ $t("authorLabel") }}</strong> {{ post.author }}
                </p>
              </div>
              <div class="column">
                <p>
                  <strong>{{ $t("createdOnLabel") }}</strong>
                  {{ formattedCreatedAt }}
                </p>
              </div>
            </div>

            <!-- Sharing Section with Icons -->
            <div class="buttons">
              <button @click="sharePost" class="button is-rounded is-small">
                <span class="icon" v-if="!isRtl">
                  <i class="fas fa-share-alt"></i>
                </span>
                <span>{{ $t("shareBtn") }}</span>
              </button>
              <button
                @click="copyUrlToClipboard"
                class="button is-rounded is-small"
              >
                <span class="icon" v-if="!isRtl">
                  <i class="fas fa-link"></i>
                </span>
                <span>{{ $t("copyLinkBtn") }}</span>
              </button>
            </div>

            <!-- Comment Section -->
            <div class="section">
              <div class="container">
                <h3 class="title is-3">{{ $t("commentsLabel") }}</h3>
                <div>
                  <comments-view :docId="docId" class="mb-5" />
                  <div v-if="isAuthenticated">
                    <create-comment :docId="docId" />
                  </div>
                  <div v-else class="box login-signup-box">
                    <h1 class="subtitle is-4">{{ $t("signUpAlert") }}</h1>
                    <div v-if="displayLogin">
                      <login-component />
                    </div>
                    <div v-else>
                      <signup-component />
                    </div>
                    <a class="mt-3">
                      <span
                        v-if="displayLogin"
                        @click="displayLogin = !displayLogin"
                      >
                        {{ $t("createAccLabel") }}
                      </span>
                      <span @click="displayLogin = !displayLogin" v-else>
                        {{ $t("loginAccLabel") }}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Sidebar with Last 6 Posts -->
        <div class="column is-one-third">
          <div class="box">
            <h3 class="title is-4">{{ $t("latestPostsLabel") }}</h3>
            <div>
              <div
                v-for="recentPost in recentPosts"
                :key="recentPost.id"
                class="mb-4"
              >
                <MiniPostCard :post="recentPost" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watchEffect, onBeforeUnmount } from "vue";
import { useRoute } from "vue-router";
import { db } from "@/firebase/config";
import {
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  updateDoc,
  increment,
} from "firebase/firestore";
import CommentsView from "@/components/CommentsView.vue";
import CreateComment from "@/components/CreateComment.vue";
import { useStore } from "vuex";
import LoginComponent from "@/components/LoginComponent.vue";
import SignupComponent from "@/components/SignupComponent.vue";
import { getCurrentInstance } from "vue";
import MiniPostCard from "@/components/MiniPostCard.vue"; // Import the new component

export default {
  components: {
    CommentsView,
    CreateComment,
    LoginComponent,
    SignupComponent,
    MiniPostCard, // Register the new component
  },
  setup() {
    const post = ref({});
    const recentPosts = ref([]);
    const route = useRoute();
    const docId = ref(route.params.docId);
    const currentUrl = ref("www.thesyrianblog.com" + window.location.pathname);
    const isLoading = ref(true);
    const store = useStore();
    const displayLogin = ref(true);

    const fetchPostData = async () => {
      const postRef = doc(db, "Posts", docId.value);
      const postDoc = await getDoc(postRef);
      post.value = postDoc.exists() ? postDoc.data() : {};

      // Fetch the latest posts (excluding the current one)
      const postsQuery = query(
        collection(db, "Posts"),
        orderBy("createdAt", "desc"),
        limit(6)
      );
      const querySnapshot = await getDocs(postsQuery);
      recentPosts.value = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((p) => p.id !== docId.value);

      // Increment View Count
      updateDoc(postRef, { viewCount: increment(1) });

      // Attach Scroll Listener to increment Read Count
      window.addEventListener("scroll", handleScroll);

      isLoading.value = false;
    };

    // Remove Scroll Listener on Component Unmount
    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    // Handle Scroll Event to increment Read Count
    const handleScroll = () => {
      // Check if User has scrolled to the end of the Article
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 1000
      ) {
        // Reference to the Post Document
        const postRef = doc(db, "Posts", docId.value);

        // Increment Read Count
        updateDoc(postRef, { readCount: increment(1) });

        // Remove the event listener after incrementing the read count
        window.removeEventListener("scroll", handleScroll);
      }
    };

    const copyUrlToClipboard = () => {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(currentUrl.value)
          .then(() => {
            console.log("URL copied to clipboard successfully!");
          })
          .catch((err) => {
            console.error("Could not copy URL: ", err);
          });
      } else {
        // Fallback method here (like the textarea one mentioned above)
      }
    };

    const sharePost = () => {
      if (navigator.share) {
        navigator
          .share({
            title: post.value.title,
            text: post.value.description,
            url: currentUrl.value,
          })
          .then(() => {
            console.log("Thanks for sharing!");
          })
          .catch(console.error);
      } else {
        console.log("Web Share API not supported.");
      }
    };

    watchEffect(() => {
      docId.value = route.params.docId;
      fetchPostData(); // Re-fetch data when the docId changes
    });

    const formattedCreatedAt = computed(() => {
      // Assuming createdAt is stored as a Firebase timestamp
      return post.value.createdAt?.toDate().toLocaleDateString();
    });

    const instance = getCurrentInstance(); // Get the current component instance

    const isRtl = computed(() => {
      if (instance && instance.proxy.$i18n) {
        return instance.proxy.$i18n.locale === "en-ar";
      }
    });

    return {
      post,
      formattedCreatedAt,
      recentPosts,
      currentUrl,
      displayLogin,
      isLoading,
      copyUrlToClipboard,
      sharePost,
      isRtl,
      docId,
      isAuthenticated: computed(() => store.getters.isAuthenticated),
    };
  },
};
</script>

<style scoped>
/* Padding and Container Styles */
.container {
  max-width: 80vw; /* Match the home view's max-width */
  padding: 0 20px; /* Fixed padding on the sides */
}

/* Adjust columns spacing */
.columns {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.column {
  padding: 0.75rem;
}

/* Loading Container */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

/* Comment Section Styles */
.login-signup-box {
  background: #f9f9f9; /* Light Background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #0000001a;
  text-align: center;
}

/* Remove Default Bulma Box Margins */
.box {
  margin: 0;
}

/* Sidebar Styles */
.box {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow effect */
  border-radius: 5px; /* Rounded corners */
}

.box h3.title {
  margin-bottom: 1rem;
}

/* Adjust MiniPostCard Styles */
.mini-post-card {
  text-decoration: none;
  color: inherit;
}

.mini-post-card .card {
  box-shadow: none;
  border: none;
}

.mini-post-card .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.mini-post-card .card-image {
  margin-bottom: 0.5rem;
}

.mini-post-card .card-content {
  padding: 0;
}

.mini-post-card .title.is-6 {
  margin-bottom: 0.25rem;
}

.mini-post-card .subtitle.is-7 {
  margin-bottom: 0;
  color: #7a7a7a;
}
</style>
