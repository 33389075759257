<template>
  <router-link
    :to="{
      name: 'PostView',
      params: { slug: post.title, docId: post.id },
    }"
    class="no-link-color"
  >
    <div class="box post-card">
      <!-- Image Section -->
      <div class="post-image">
        <figure class="image is-4by3">
          <img :src="post.imageUrl" alt="Blog Image" v-if="post.imageUrl" />
          <img src="../assets/missingImage.jpeg" alt="Missing Image" v-else />
        </figure>
      </div>

      <!-- Text Section -->
      <div class="post-content">
        <!-- Title and Description -->
        <h1 class="title">{{ post.title }}</h1>
        <p class="subtitle mt-2">{{ post.description }}</p>

        <!-- Author and Date -->
        <div class="meta">
          <small class="author">{{ post.author }}</small>
          <small class="date">{{ formattedDate }}</small>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const formattedDate = computed(() => {
      const date = new Date(props.post.createdAt.seconds * 1000);
      return date.toLocaleDateString();
    });

    return {
      formattedDate,
      isAuthenticated: computed(() => store.getters.isAuthenticated),
      userProfile: computed(() => store.state.userProfile),
    };
  },
};
</script>

<style scoped>
/* Ensure the router-link behaves like a block element */
.no-link-color {
  display: block;
  color: inherit;
  text-decoration: none;
}

/* Remove default margins from Bulma's .box class */
.post-card.box {
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
}

/* Set the card to fill its parent column */
.post-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Image Styling */
.post-image {
  flex-shrink: 0;
}

/* Content Styling */
.post-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px; /* Add padding to the content */
}

/* Meta Information */
.meta {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.meta small {
  font-size: 0.9rem;
  color: #7a7a7a;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}
</style>
