// Import Vuex and Firebase functions
import { createStore } from 'vuex'
import {
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  doc,
  onSnapshot
} from "firebase/firestore";
import { auth, db } from "@/firebase/config";
import { watchEffect } from "vue";

// Create Vuex store
const store = createStore({
  state: {
    user: null, // Current authenticated user
    userProfile: null, // Profile information of authenticated user
    authIsReady: false, // Flag to track if auth status is ready
    versionNumber: 'V 3.0.1', // Version number of the application
  },
  getters: {
    isAuthenticated: state => !!state.user && state.authIsReady, // Computed property to check if the user is authenticated
  },
  mutations: {
    // Mutations to update the state
    setUser(state, payload) {
      state.user = payload
      //console.log('User state changed: ', state.user)
    },
    setAuthIsReady(state, payload) {
      state.authIsReady = payload
      //console.log('Auth is ready')
    },
    setUserProfile(state, payload) {
      state.userProfile = payload
      //console.log('User profile state changed: ', state.userProfile)
    },
  },
  actions: {
    // Action for logging in a user
    async login(context, { email, password }) {
      //console.log('Login Action')
      const res = await signInWithEmailAndPassword(auth, email, password)
      if (res) {
        let docRef = doc(db, 'Users', res.user.uid)
        const unsub2 = onSnapshot(docRef, doc => {
          //console.log(doc.data().role)
          if (doc.data()) {
            context.commit('setUser', res.user)
            context.commit('setUserProfile', { ...doc.data() })
          } else {
            //console.log('Invalid User')
            signOut(auth)
          }
        }, (err) => {
          //console.log(err.message)
        })
        watchEffect((onInvalidate) => {
          onInvalidate(() => unsub2()) // unsubscribe from the realtime listener
        })
      } else {
        throw Error('Could not complete login')
      }
    },
    // Action for logging out a user
    async logout(context) {
      //console.log('Logout Action')
      await signOut(auth)
      context.commit('setUser', null)
      context.commit('setUserProfile', null)
    },
    // Action to check authentication state
    checkAuth({ commit }) {
      return new Promise((resolve) => {
        auth.onAuthStateChanged(user => {
          commit('setUser', user);

          if (user) {
            let docRef = doc(db, 'Users', user.uid)
            const unsub2 = onSnapshot(docRef, doc => {
              if (doc.data()) {
                commit('setUser', user)
                commit('setUserProfile', { ...doc.data() })
              } else {
                //console.log('Invalid User')
                signOut(auth)
              }
            }, (err) => {
              //console.log(err.message)
            })
            watchEffect((onInvalidate) => {
              onInvalidate(() => unsub2()) // unsubscribe from the realtime listener
            })
          }
          commit('setAuthIsReady', true);
          resolve(user);
        });
      });
    },
  },
  modules: {
    // Vuex modules can be added here
  }
})


// Exporting the store for use in the main application
export default store
