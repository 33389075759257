<template>
  <nav class="navbar is-fixed-top has-shadow">
    <div class="container">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item">
          <!-- You can replace the text with a logo image if you have one -->
          <h1
            class="title is-3"
            style="
              font-family: 'Anton', sans-serif;
              font-weight: 400;
              font-style: normal;
            "
          >
            <b> {{ $t("title") }}</b>
          </h1>
        </router-link>

        <!-- Hamburger menu for mobile -->
        <a
          role="button"
          class="navbar-burger"
          :class="{ 'is-active': menuActive }"
          aria-label="menu"
          aria-expanded="false"
          @click="toggleMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div class="navbar-menu" :class="{ 'is-active': menuActive }">
        <!-- Adjusted class binding for navbar-start/navbar-end -->
        <div :class="isRtl ? 'navbar-end' : 'navbar-start'">
          <router-link
            class="navbar-item"
            :class="isRtl ? 'mr-5' : ''"
            to="/"
            >{{ $t("blog") }}</router-link
          >
          <router-link class="navbar-item" to="/about">{{
            $t("about")
          }}</router-link>
          <router-link class="navbar-item" to="/contact">{{
            $t("contact")
          }}</router-link>
        </div>

        <!-- Adjusted class binding for navbar-end/navbar-start -->
        <div :class="isRtl ? 'navbar-start' : 'navbar-end'">
          <!-- Language Selector -->
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
              <span class="icon">
                <i class="fas fa-globe"></i>
              </span>
              <span>{{ currentLanguageLabel }}</span>
            </a>
            <div class="navbar-dropdown">
              <a class="navbar-item" @click="changeLanguage('en-en')"
                >English</a
              >
              <a class="navbar-item" @click="changeLanguage('en-ar')">عربي</a>
            </div>
          </div>

          <!-- Authentication Buttons -->
          <div class="navbar-item">
            <div class="buttons">
              <button
                class="button is-danger is-light"
                @click="logout"
                v-if="isAuthenticated"
              >
                <span class="icon">
                  <i class="fas fa-sign-out-alt"></i>
                </span>
                <span>{{ $t("logout") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>


<script>
import { computed, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const menuActive = ref(false);

    const logout = async () => {
      //console.log("Logging out...");
      await store.dispatch("logout");
      router.push({ name: "home" });
    };

    const toggleMenu = () => {
      menuActive.value = !menuActive.value; // Toggle the menu
    };
    const instance = getCurrentInstance(); // Get the current component instance

    const selectedLanguage = ref(); // default language

    if (instance && instance.proxy.$i18n) {
      selectedLanguage.value = instance.proxy.$i18n.locale;
    }

    const changeLanguage = (lang) => {
      if (instance && instance.proxy.$i18n) {
        instance.proxy.$i18n.locale = lang;
        selectedLanguage.value = lang;
      }
    };

    const isRtl = computed(() => {
      if (instance && instance.proxy.$i18n) {
        return instance.proxy.$i18n.locale === "en-ar";
      }
    });

    const currentLanguageLabel = computed(() => {
      return selectedLanguage.value === "en-ar" ? "عربي" : "English";
    });

    return {
      logout,
      isAuthenticated: computed(() => store.getters.isAuthenticated),
      menuActive,
      changeLanguage,
      toggleMenu,
      selectedLanguage,
      isRtl,
      currentLanguageLabel,
    };
  },
};
</script>

<style scoped>
.navbar {
  background-color: #fff;
}

.navbar-item h1 {
  font-size: 1.5rem;
  color: #333;
}

.navbar-burger {
  color: #333;
}

.navbar-menu .navbar-start .navbar-item,
.navbar-menu .navbar-end .navbar-item {
  font-weight: 600;
}

.navbar-menu .navbar-item:hover {
  background-color: #f5f5f5;
}

.navbar-dropdown .navbar-item:hover {
  background-color: #f5f5f5;
}

.buttons .button {
  margin-left: 0.5rem;
}

@media (max-width: 80vw) {
  .navbar-menu {
    background-color: #fff;
  }

  .navbar-menu .navbar-end {
    border-top: 1px solid #ddd;
  }
}
</style>
